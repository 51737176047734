<template>
  <div class="z-10 inset-x-0 transform shadow-lg bg-gray-100">
    <div class=" max-w-auto mx-auto">
      <form
        class="space-y-8 divide-y divide-gray-200 form_open_ticket_padding"
        @submit.prevent="onSubmit($t('ticketTable.openMsg'))"
        method="POST"
      >
        <div class="space-y-8 divide-y divide-gray-200">
          <div>
            <div>
              <div class="inline-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <h3 class="text-lg pl-2 leading-6 font-medium text-gray-900">
                  {{ $t("ticket.ticketForm") }}
                </h3>
              </div>
              <p class="mt-1 text-sm text-gray-500">
                {{ $t("ticket.typeDown") }}
              </p>
            </div>
            <TicketDetailHeader
              :ticketContacts="associatedContacts"
              :customerData="customer"
              :coworkers="filterContactTeam()"
              v-model:searchContact="searchContact"
              @addContactOpenTicket="getContact($event)"
              @removeContactOpenTicket="removeContact($event)"
            />

            <br />
            <div class="grid col-span-8">
              <div class="col-start-1 col-end-2">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ $t("ticket.category") }}
                </label>
                <select
                  @change="getCategoryId($event)"
                  id="country"
                  name="country"
                  autocomplete="country"
                  class="mt-1 block w-2/3 py-2 px-3 border border-gray-300 bg-white  shadow-sm  sm:text-sm rounded"
                >
                  <option value="">Select Category</option>
                  <option
                    v-for="category in ticketsCategories"
                    :key="category.name"
                    :value="category.id"
                    >{{ category.name }}</option
                  >
                </select>
              </div>
              <div class="col-end-3">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700 capitalize"
                >
                  {{ $t("ticket.priority") }}
                </label>
                <select
                  v-model="form.priority_id"
                  id="country"
                  name="country"
                  required=""
                  autocomplete="country"
                  class="mt-1 block w-2/3 pl-3 py-2 text-base border-gray-300 sm:text-sm rounded"
                >
                  <option value="">Select Priority</option>
                  <option
                    v-for="priority in priorities"
                    :key="priority.name"
                    :value="priority.value"
                    ><span class="capitalize">{{ priority.name }}</span></option
                  >
                </select>
              </div>
              <div class="col-end-4">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700 capitalize"
                >
                  {{ $t("ticket.status") }}
                </label>
                <select
                  id="status"
                  v-model="form.status_id"
                  name="status"
                  class="mt-1 block w-1/2 pl-3 py-2 text-base border-gray-300 sm:text-sm rounded"
                >
                  <option value="">Select Status</option>
                  <option
                    :value="status.id"
                    v-for="status in statuses"
                    :key="status.id"
                    >{{ status.name }}</option
                  >
                </select>
              </div>
              <div class="inline-block">
                <label
                  for="quickSearch"
                  class="block text-sm font-medium text-gray-700 capitalize"
                  >{{ $t("ticket.concerns") }}</label
                >
                <div class="mt-1">
                  <QuickSearch
                    id="quickSearch"
                    @action="putTag($event)"
                    :placeholder="'Search'"
                    :searchMethod="getConcerns"
                    :data="concerns"
                  />
                </div>
              </div>
              <!-- <div class="col-start-4 col-end-7">
                <div class="relative inline-block text-left" ref="contactsList">
                  <label
                    for="search"
                    class="block text-sm font-medium text-gray-700 capitalize"
                    >{{ $t("ticket.concerns") }}</label
                  >
                  <div class="relative">
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
                    >
                      <SearchIcon
                        class="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      @change="putTag($event)"
                      list="concernList"
                      v-model="searchConcern"
                      class="block w-full bg-white border border-gray-300 mt-1 py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Search"
                      type="search"
                    />
                    <datalist id="concernList">
                      <option
                        v-for="concern in concerns"
                        :value="concern.value"
                        :key="concern.value"
                      >
                      </option>
                    </datalist>
                  </div>
                </div>
              </div> -->
              <div class="col-end-8">
                <label
                  class="block text-sm font-medium text-gray-700 capitalize"
                >
                  {{ $t("ticket.resolution") }}
                </label>
                <v-date-picker
                  :min-date="calculateMinDate()"
                  v-model="date"
                  mode="dateTime"
                  is24hr
                  :minute-increment="5"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="w-1/2 mt-1.5 px-2 py-1 body__table--body__text border border-gray-300 rounded"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </v-date-picker>
              </div>
            </div>
            <fieldset class="priority_selection" v-if="categoryId">
              <div>
                <legend class="text-base font-medium text-gray-900">
                  {{ $t("ticket.subCategory") }}
                </legend>
                <p class="text-sm text-gray-500">
                  {{ $t("ticket.selectProblem") }}.
                </p>
              </div>
              <div
                class="mt-1 space-y-1"
                v-for="subCategory in matchingSubCategories"
                :key="subCategory.name"
              >
                <div class="flex items-center">
                  <input
                    v-model="form.ticket_sub_category_id"
                    :id="subCategory.name"
                    :name="subCategory.name"
                    type="radio"
                    class="rounded h-4 w-4 body__table--body__text border-gray-300 rounded"
                    :value="subCategory.id"
                  />
                  <label
                    :for="subCategory.name"
                    class="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {{ subCategory.name }}
                  </label>
                </div>
              </div>
            </fieldset>
            <div class="mt-6 grid grid-cols-8 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="col-start-1 col-span-4">
                <label
                  for="username"
                  class="block text-sm font-medium text-gray-700"
                >
                  {{ $t("ticket.subject") }}
                </label>
                <div class="mt-1 flex shadow-sm">
                  <input
                    id="subject"
                    v-model="form.subject"
                    name="subject"
                    type="text"
                    required=""
                    :placeholder="$t('ticket.emailSubject')"
                    class="flex-1 focus:encom_border_input  block w-full min-w-0 sm:text-sm border-gray-300 rounded"
                  />
                </div>
              </div>
              <div class="col-span-2">
                <label
                  for="country"
                  class="block text-sm font-medium text-gray-700 capitalize"
                >
                  {{ $t("ticket.template") }}
                </label>
                <select
                  @change="putTemplate($event)"
                  id="status"
                  name="status"
                  class="mt-1 block w-1/2 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded"
                >
                  <option value="">Select template</option>
                  <option
                    v-for="template in ticketTemplates"
                    :value="template.name"
                    :key="template.id"
                    >{{ template.name }}</option
                  >
                </select>
              </div>

              <div class="sm:col-span-6">
                <div class="mt-1 z-10 ">
                  <ckeditor
                    class="rounded"
                    :editor="editor"
                    v-model="editorData"
                    :config="editorConfig"
                  ></ckeditor>
                </div>
                <p class="mt-2 text-sm text-gray-500">
                  {{ $t("ticket.typeAbove") }}.
                </p>
              </div>

              <div class="inline-block">
                <label for="form_tags">#Tags</label>
                <textarea
                  class="rounded"
                  name="form_tags"
                  id="form_tags"
                  v-model="form.tags"
                  cols="50"
                  rows="2"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-5">
          <div class="flex justify-end">
            <a
              @click.prevent="cancelForm"
              href="javascript:void(0)"
              class="bg-white py-2 px-4 border border-gray-300  shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {{ $t("ticket.cancel") }}
            </a>
            <button
              type="submit"
              :class="
                `rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white`
              "
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <ComfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import {
  SortAscendingIcon,
  ChevronDownIcon,
  SearchIcon,
} from "@heroicons/vue/solid";
import { mapGetters } from "vuex";
import ComfirmationDialog from "../../components/ConfirmationDialog.vue";
import TicketDetailHeader from "../tickets/TicketDetailHeader.vue";
import QuickSearch from "../../components/QuickSearch.vue";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

import ImageInsertPlugin from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImagePlugin from "@ckeditor/ckeditor5-image/src/image";
import ImageToolbarPlugin from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import AutoImagePlugin from "@ckeditor/ckeditor5-image/src/autoimage";
import ImageResizePlugin from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageStylePlugin from "@ckeditor/ckeditor5-image/src/imagestyle";

import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";

const priorities = [
  { name: "Low", value: 1 },
  { name: "Medium", value: 2 },
  { name: "High", value: 3 },
  { name: "Critical", value: 4 },
];

const minDate = new Date().setHours(new Date().getHours() + 4);

export default {
  name: "OpenTicket",
  props: ["id", "type", "contactId"],
  emits: ["close"],
  components: {
    SearchIcon,
    ChevronDownIcon,
    SortAscendingIcon,
    ComfirmationDialog,
    TicketDetailHeader,
    QuickSearch,
  },
  data() {
    return {
      date: undefined,
      minDate,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        fillEmptyBlocks: false,
        tabSpaces: 0,
        placeholder: "Write your reply here...",
        plugins: [
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          LinkPlugin,
          ParagraphPlugin,
          ImageInsertPlugin,
          SimpleUploadAdapter,
          ImagePlugin,
          ImageResizePlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          AutoImagePlugin,
        ],
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: `${this.$cookie.getCookie("API")}/api/v1/pictures`,

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: {
            "X-CSRF-TOKEN": "CSRF-Token",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
        toolbar: {
          items: [
            "heading",
            "bold",
            "italic",
            "|",
            "link",
            "|",
            "undo",
            "redo",
            "|",
            "indent",
            "outdent",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "insertImage",
          ],
        },
        indentBlock: {
          offset: 1,
          unit: "em",
        },
        language: "en",
        image: {
          toolbar: [
            "imageTextAlternative",
            "imageStyle:inline",
            "imageStyle:block",
            "imageStyle:side",
          ],
        },
        licenseKey: "",
      },
      form: {
        subject: "",
        body: "",
        ticket_sub_category_id: null,
        ticket_category_id: null,
        priority_id: "",
        status_id: "",
        tags: "",
      },
      ticketsCategories: [],
      categoryId: null,
      subCategories: [],
      matchingSubCategories: [],
      closeForm: true,
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
      statuses: [],
      customer: {},
      ticketTemplates: [],
      priorities,
      coworkers: [],
      searchContact: "",
      associatedContacts: [],
      searchConcern: "",
      concerns: [],
    };
  },
  methods: {
    calculateMinDate() {
      let defaultEstimatedHours = 4;
      let estimatedResolutionDate = new Date();
      let maxHour = new Date().setHours(18, 0, 0);
      let minHour = new Date().setHours(8, 0, 0);
      let minDate = new Date().setHours(
        new Date().getHours() + defaultEstimatedHours
      );
      let maxHourTime = new Date(maxHour).getTime();
      let minDateTime = new Date(minDate).getTime();
      let hourDifference = null;
      if (minDateTime > maxHourTime) {
        hourDifference = minDateTime - maxHourTime;
        let setNewDate = new Date().setDate(new Date().getDate() + 1);
        estimatedResolutionDate = new Date(setNewDate).setHours(
          new Date(minHour).getHours() +
            new Date(hourDifference).getHours() -
            1,
          new Date().getMinutes(),
          new Date().getSeconds()
        );
      } else {
        estimatedResolutionDate = minDate;
      }
      this.date = new Date(estimatedResolutionDate);
      return new Date(estimatedResolutionDate);
    },
    async onSubmit(msg) {
      // if (this.form.ticket_sub_category_id) {
      //   this.subCategories.map((sub) => {
      //     if (sub.id == this.form.ticket_sub_category_id) {
      //       this.form.priority_id = sub.priority_id;
      //     }
      //   });
      // } else {
      //   this.form.priority_id = 2;
      // }
      let str = String(this.editorData);
      let noSpace = str.replaceAll("&nbsp;", "");
      let data = {
        subject: this.form.subject,
        estimatedResolutionDate: this.parseResolutionDate(this.date),
        body: noSpace,
        priority_id: this.form.priority_id,
        ticket_category_id: this.form.ticket_category_id,
        ticket_sub_category_id: this.form.ticket_sub_category_id,
        status_id: this.form.status_id,
        tags: this.form.tags,
      };
      if (this.associatedContacts.length) {
        axios
          .post(
            `${this.$cookie.getCookie("API")}/api/v1/tickets?ticketable_id=${
              this.id
            }&ticketable_type=App\\Customer`,
            data
          )
          .then((res) => {
            if (res) {
              this.form.subject = "";
              this.form.body = "";
              this.closeForm = !this.closeForm;
              this.associateContacts(res.data.id, msg);
            } else {
              window.alert("Something went wrong.");
            }
          })
          .catch((err) => {
            console.error(err);
            if (err.response.status == 401) {
              localStorage.removeItem("token");
              this.$store.dispatch("token", null);
              this.$router.push("/login");
            }
          });
      } else {
        window.alert("Please associate a contact to the ticket.");
      }
    },
    async associateContacts(ticketId, msg) {
      let contactsId = [];

      for (let index = 0; index < this.associatedContacts.length; index++) {
        contactsId.push(this.associatedContacts[index].id);
      }
      try {
        const options = {
          method: "PUT",
          url: `${this.$cookie.getCookie(
            "API"
          )}/api/v1/tickets/${ticketId}/associates`,
          data: { associated: contactsId, unassociated: [] },
        };

        axios
          .request(options)
          .then((response) => {
            this.sendMessage(msg);
            this.$forceUpdate();
          })
          .catch((error) => {
            console.error(error);
            if (error.response.status == 401) {
              localStorage.removeItem("token");
              this.$store.dispatch("token", null);
              this.$router.push("/login");
            }
          });
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getTicketTemplates() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/ticketTemplates`
        );
        if (res) {
          this.ticketTemplates = res.data;
          this.editorData = res.data.filter((template) => {
            return template.default;
          });
        }
      } catch (error) {}
    },
    putTemplate(event) {
      if (event.target.value) {
        let status = this.ticketTemplates.filter((template) => {
          return template.name === event.target.value;
        });
        this.form.subject = status[0].subject;
        this.editorData = status[0].body;
      } else {
        this.editorData = "";
        this.form.subject = "";
      }
    },
    cancelForm() {
      this.form.subject = "";
      this.form.body = "";
      this.form.ticket_sub_category_id = null;
      this.form.ticket_category_id = null;
      this.form.priority_id = null;
      this.form.status_id = "";
      this.form.tags = "";
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
    async getTicketCategories() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/ticketCategories`
        );
        this.ticketsCategories = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getCustomer() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/customers/${this.id}`
        );
        this.customer = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getContact(id) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/contacts/${id}`
        );
        if (this.associatedContacts.length) {
          for (let index = 0; index < this.associatedContacts.length; index++) {
            if (this.associatedContacts[index].id !== res.data.id) {
              this.associatedContacts.push(res.data);
            }
          }
        } else {
          this.associatedContacts.push(res.data);
        }
      } catch (error) {
        this.errorHandling(error);
      }
    },
    removeContact(id) {
      let contactIndex = "";
      let hasContact = false;

      for (let index = 0; index < this.associatedContacts.length; index++) {
        if (this.associatedContacts[index].id == id) {
          hasContact = true;
          contactIndex = index;
        }
      }
      if (hasContact) {
        this.associatedContacts.splice(contactIndex, 1);
      }
    },
    async getCoworkers() {
      if (this.contactId) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/contacts/${
              this.contactId
            }/coworkers`
          );
          this.coworkers = res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    filterContactTeam() {
      return this.coworkers.filter((worker) => {
        if (worker.firstName && worker.lastName) {
          return (
            worker.firstName
              .toLowerCase()
              .includes(this.searchContact.toLowerCase()) ||
            worker.lastName
              .toLowerCase()
              .includes(this.searchContact.toLowerCase())
          );
        } else if (!worker.firstName && worker.lastName) {
          return worker.lastName
            .toLowerCase()
            .includes(this.searchContact.toLowerCase());
        } else if (worker.firstName && !worker.lastName) {
          worker.firstName
            .toLowerCase()
            .includes(this.searchContact.toLowerCase());
        }
      });
    },
    async getStatuses() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/statuses`
        );
        this.statuses = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    getCategoryId(event) {
      this.categoryId = event.target.value;
      this.form.ticket_category_id = parseInt(event.target.value);
      this.checkSubCategories(event.target.value);
    },
    async getSubCategories() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/ticketSubCategories`
        );
        this.subCategories = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    checkSubCategories(id) {
      this.matchingSubCategories = [];
      for (let index = 0; index < this.subCategories.length; index++) {
        if (this.subCategories[index].ticket_category_id == id) {
          this.matchingSubCategories.push(this.subCategories[index]);
        }
      }
    },
    parseResolutionDate(date) {
      let newDate = new Date(date);
      let year = newDate.getFullYear();
      let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
      let day = ("0" + newDate.getDate()).slice(-2);
      let hours = ("0" + newDate.getHours()).slice(-2);
      let minutes = ("0" + newDate.getMinutes()).slice(-2);
      let seconds = ("0" + newDate.getSeconds()).slice(-2);
      let fullDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;
      return fullDate;
    },
    async getConcerns(term) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie(
            "API"
          )}/api/v1/customerConcerns/search?term=${term}`
        );
        this.concerns = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
    putTag(event) {
      console.log(event.value);
      if (this.form.tags.length > 0) {
        this.form.tags = this.form.tags + ", " + event.value;
      } else {
        this.form.tags = event.value;
      }
    },
  },
  mounted() {
    this.getTicketCategories();
    this.getSubCategories();
    this.getStatuses();
    this.getCustomer();
    this.getTicketTemplates();
    this.getCoworkers();
    this.getContact(this.contactId);
  },
  computed: {
    ...mapGetters(["language"]),
  },
  watch: {
    searchConcern: function(val) {
      if (val.length > 2) {
        this.getConcerns(val);
      }
    },
  },
};
</script>

<style>
.ck {
  height: 39vh;
}
</style>
